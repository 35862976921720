import { Divider } from "@mui/material";
import { unpackResponse, useClient } from "../client";
import { Schema } from "../client/types";
import { ConfirmDialog } from "./confirm-dialog";
import { MoreMenu, MoreMenuDialogItem } from "./more-menu";
import { EditWarrantyReturnDialog } from "./warranty-return-dialog";

export function WarrantyReturnMenu(props: {
  warrantyReturn: Schema["WarrantyReturn"];
  revalidateView: () => void;
}) {
  const client = useClient();

  return (
    <MoreMenu>
      <MoreMenuDialogItem
        renderDialog={(dialog) => (
          <ConfirmDialog
            {...dialog}
            title="Mark warranty return as received?"
            description="This warranty return will be marked as received."
            submitLabel="Mark as received"
            onSubmit={async () => {
              await unpackResponse(
                client.PATCH(
                  "/locations/{locationId}/warranty-returns/{warrantyReturnId}/receive",
                  {
                    params: {
                      path: {
                        locationId: props.warrantyReturn.locationId,
                        warrantyReturnId: props.warrantyReturn.id,
                      },
                    },
                  },
                ),
              );

              props.revalidateView();
            }}
          />
        )}
      >
        Mark as received
      </MoreMenuDialogItem>

      <MoreMenuDialogItem
        renderDialog={(dialog) => {
          return (
            <EditWarrantyReturnDialog
              {...dialog}
              {...props}
              onSuccess={props.revalidateView}
            />
          );
        }}
      >
        Edit
      </MoreMenuDialogItem>

      <Divider />

      <MoreMenuDialogItem
        renderDialog={(dialog) => (
          <ConfirmDialog
            {...dialog}
            title="Delete warranty return"
            description="This warranty return will be permanently deleted."
            submitLabel="Delete"
            submitColor="error"
            onSubmit={async () => {
              await unpackResponse(
                client.DELETE(
                  "/locations/{locationId}/warranty-returns/{warrantyReturnId}",
                  {
                    params: {
                      path: {
                        locationId: props.warrantyReturn.locationId,
                        warrantyReturnId: props.warrantyReturn.id,
                      },
                    },
                  },
                ),
              );

              props.revalidateView();
            }}
          />
        )}
      >
        Delete
      </MoreMenuDialogItem>
    </MoreMenu>
  );
}
