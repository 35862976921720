import { Box, Button, Typography } from "@mui/material";
import { Gift } from "react-feather";
import { Link } from "react-router-dom";
import { useClientSWR } from "../client";
import { Schema } from "../client/types";
import { paymentFrequency } from "../constants/paymentFrequency";
import { useQueryPermissions } from "../hooks/useQueryPermissions";
import { EnumFormatter, EnumMapper } from "../utils/enum-formatter";
import { MessageFormat, mf } from "../utils/formatters";
import { DummyLine } from "./dummy-line";
import { TextWithIcon } from "./issue-text";
import { useUserHasRole } from "./role-guard";
import { ViewCard } from "./view-card";

type StatusWithoutActive = Exclude<Schema["SubscriptionStatus"], "ACTIVE">;

const content = {
  heading: "Subscription details",
  fetchError: `Failed to fetch subscription details.`,
  viewButtonLabel: `View details`,
  subscriptionStatus: new EnumMapper<StatusWithoutActive, MessageFormat>(
    {
      PENDING_ACTIVATION: mf(`Subscription is pending activation.`),
      MANUAL: mf(`This location is billed manually`),
      CLOSED: mf(
        `This location has been closed on {closeDate, date, ::dMMMyyyy}.`,
      ),
    },
    mf(`Unknown subscription status`),
  ),
  subscriptionStatusActive: mf(
    `Next {frequency} payment of {value, number, ::currency/USD} due {dueDate, date, ::dMMMyyyy}.`,
  ),
  subscriptionWarning: new EnumFormatter<Schema["WarningType"]>({
    PAYMENT_OVERDUE: `Overdue payment`,
    MISSING_PAYMENT_METHOD: "Missing payment method",
    MISSING_PRIMARY_CONTACT: "Missing primary contact",
    LOCATION_NOT_SUPPORTED: "Location not supported",
    PENDING_WARRANTY_RETURN: "Pending warranty return",
  }),
  annualPromo: `Switch to annual payments and save 10%.`,
};

function SubscriptionDetailsContent(props: {
  location: Schema["LocationBase"];
  subscription: Schema["Subscription"];
}) {
  const { subscription } = props;
  const permissions = useQueryPermissions([
    { type: "VIEW_ANNUAL_PROMO", subscription },
  ]);

  switch (subscription.status) {
    case "ACTIVE": {
      // TODO: Handle warnings properly
      // const showWarnings =
      //   subscription.warnings && subscription.warnings.length > 0;

      // Promo should only be shown to company admin, for a subscription that is paid
      // monthly and that is not seasonal (it doesn't make sense for them to be steered
      // towards annual billing if they are only billed seasonally).
      const showPromo = permissions.isAllowed("VIEW_ANNUAL_PROMO");

      return (
        <>
          <Typography variant="body2">
            {content.subscriptionStatusActive.format({
              frequency: paymentFrequency
                .format(subscription.paymentFrequency)
                .toLowerCase(),
              value: subscription.expectedNextTotalAmount,
              dueDate: new Date(subscription.nextPaymentDate ?? 0),
            })}
          </Typography>

          {/* We don't show promo if there are warnings in order not to overload the user. */}
          {showPromo && (
            <Box sx={{ marginTop: 3 }}>
              <TextWithIcon icon={<Gift size="1em" />} color="success.main">
                {content.annualPromo}
              </TextWithIcon>
            </Box>
          )}
        </>
      );
    }

    case "MANUAL": {
      return (
        <Typography variant="body2">
          {content.subscriptionStatus.get(subscription.status).format()}
        </Typography>
      );
    }

    case "CLOSED": {
      const closeDate = props.location.closeDate
        ? new Date(props.location.closeDate)
        : null;

      return (
        <Typography variant="body2" color="warning.main">
          {content.subscriptionStatus.get(subscription.status).format({
            closeDate: closeDate,
          })}
        </Typography>
      );
    }

    case "PENDING_ACTIVATION":
    default: {
      return (
        <Typography variant="body2" color="warning.main">
          {content.subscriptionStatus.get(subscription.status).format()}
        </Typography>
      );
    }
  }
}

export function LocationSubscriptionDetailsCard(props: {
  locationId: number;
  heading: string;
  location?: Schema["LocationBase"];
  subscription?: Schema["Subscription"];
  error?: Schema["Error"];
}) {
  const showActions = useUserHasRole([
    "SYSTEM_ADMIN",
    "PMB_ADMIN",
    "COMPANY_ADMIN",
  ]);

  return (
    <ViewCard
      title={props.heading}
      content={
        props.error ? (
          <Typography variant="body2">{content.fetchError}</Typography>
        ) : props.subscription && props.location ? (
          <SubscriptionDetailsContent
            subscription={props.subscription}
            location={props.location}
          />
        ) : (
          <>
            <DummyLine width="60%" />
            <DummyLine width="40%" />
          </>
        )
      }
      actions={
        showActions && (
          <Button
            variant="outlined"
            component={Link}
            to={`/location/${props.locationId}/subscription`}
          >
            {content.viewButtonLabel}
          </Button>
        )
      }
    />
  );
}

export function LocationSubscriptionDetailsCardFetch(props: {
  locationId: number;

  /**
   * This is useful on the Company Subscriptions page, where different
   * locations need to be differentiated by name.
   */
  heading?: string;
}) {
  const { locationId } = props;
  const { data: location, error: locationError } = useClientSWR(
    "/locations/{locationId}",
    { params: { path: { locationId } } },
  );
  const { data: subscription, error: subscriptionError } = useClientSWR(
    "/locations/{locationId}/subscription",
    { params: { path: { locationId } } },
  );

  return (
    <LocationSubscriptionDetailsCard
      locationId={locationId}
      heading={props.heading ?? content.heading}
      subscription={subscription}
      location={location}
      error={subscriptionError || locationError}
    />
  );
}
