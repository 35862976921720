import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Gift } from "react-feather";
import { useParams } from "react-router-dom";
import { useClientSWR } from "../../client";
import { Schema } from "../../client/types";
import { accountsManagement } from "../../constants/accountsManagement";
import { monthEnum } from "../../constants/monthEnum";
import { paymentFrequency } from "../../constants/paymentFrequency";
import { paymentType } from "../../constants/paymentType";
import { subscriptionStatus } from "../../constants/subscriptionStatus";
import { useQueryPermissions } from "../../hooks/useQueryPermissions";
import { formatCurrency, formatDate, mf } from "../../utils/formatters";
import { Address } from "../address";
import { ApplyDiscountDialog } from "../apply-discount-dialog";
import { DialogButton } from "../dialog-button";
import { DummyLine } from "../dummy-line";
import { DummyRows } from "../dummy-rows";
import { LocationFetchErrorCard } from "../info-card";
import { TextWithIcon } from "../issue-text";
import LoadingSpinner from "../loading-spinner";
import {
  LocationPricingSetupDialog,
  findExistingQrTextServiceAddon,
} from "../location-pricing-setup-dialog";
import { NavLinkButton } from "../nav-link-button";
import { PaymentDetailDialog } from "../payment-detail-dialog";
import { PaymentFrequencyDialog } from "../payment-frequency-dialog";
import { PaymentStatusChip } from "../payment-status-chip";
import { PaymentStructureTable } from "../payment-structure-table";
import { RoleGuard, useUserHasRole } from "../role-guard";
import { SettleManuallyDialog } from "../settle-manually-dialog";
import { PayFeeDialog, RetryPaymentDialog } from "../settle-payment-dialog";
import { TableColumnHeadCell } from "../table-elements";
import { useDownloadClient } from "../use-download-client";
import { usePageable } from "../use-pageable";
import { ViewCard } from "../view-card";
import { ViewHeader } from "../view-header";
import { ViewTable } from "../view-table";
import { ViewTablePagination } from "../view-table-pagination";
import { getPaymentMethodName } from "./stripe-brand-formatter";

export const content = {
  pricingSetupHeading: `Pricing setup`,
  pricingSetupBillingType: mf(`Billing type {status}`),
  pricingSetupSeasonal: mf(`Seasonal billing active ({from} to {to})`),
  pricingSetupTransactionHandling: mf(`Transaction handling: {handling}`),
  qrTextServiceAddon: mf(`QR text service: {tier}`),
  updateButtonLabel: `Update`,
  billingAddressHeading: `Billed to`,
  subscriptionDetailsHeading: `Subscription details`,
  paymentFrequencyHeading: mf(`Payment frequency — {frequency}`),
  paymentFrequencyBody: mf(
    `Your subscription renews on {nextPaymentDate, date, ::dMMMMyyyy}.`,
  ),
  paymentFrequencyBodyInactive: `Next payment date will be set once subscription is active.`,
  annualPromo: `Switch to annual payments and save 10%.`,

  subscriptionLumpsumDiscount: mf(
    `Discount {lumpsumDiscount, number, ::currency/USD}`,
  ),
  subscriptionPercentageDiscount: mf(
    `Discount {percentageDiscount, number, ::percent}`,
  ),
  discountValidUntil: mf(`(valid until {discountValidTo, date, ::dMMMyyyy})`),
};

type ViewProps = {
  location: Schema["LocationBase"];
  paymentStructure: Schema["PaymentStructure"];
  subscription: Schema["Subscription"];
  revalidateView: () => void;
};

function SubscriptionDetailsCard(props: ViewProps) {
  return (
    <Card>
      <CardHeader
        title={content.subscriptionDetailsHeading}
        titleTypographyProps={{ variant: "h5", component: "h2" }}
      />
      <PaymentStructureTable {...props} />
    </Card>
  );
}

function PricingSetupCard(props: ViewProps) {
  const { subscription } = props;
  const qrTextServiceAddon = findExistingQrTextServiceAddon(
    subscription.addons,
  );

  function formatSubscriptionDiscount(subscription: Schema["Subscription"]) {
    const {
      discountType,
      percentageDiscount,
      lumpsumDiscount,
      discountValidTo,
    } = subscription;

    if (discountType === "LUMPSUM" && lumpsumDiscount) {
      const discountMessage = content.subscriptionLumpsumDiscount.format({
        lumpsumDiscount,
      });

      return discountValidTo
        ? `${discountMessage} ${content.discountValidUntil.format({
            discountValidTo: new Date(discountValidTo),
          })}`
        : discountMessage;
    }

    if (subscription.discountType === "PERCENTAGE" && percentageDiscount) {
      const discountMessage = content.subscriptionPercentageDiscount.format({
        percentageDiscount: percentageDiscount / 100,
      });

      return discountValidTo
        ? `${discountMessage} ${content.discountValidUntil.format({
            discountValidTo: new Date(discountValidTo),
          })}`
        : discountMessage;
    }
  }

  function formatBillingType(subscription: Schema["Subscription"]) {
    const status = subscriptionStatus.format(subscription.status);
    const message = content.pricingSetupBillingType.format({ status });
    const frequency = paymentFrequency.format(subscription.paymentFrequency);

    return subscription.status === "ACTIVE"
      ? `${message} (${frequency})`
      : message;
  }

  return (
    <RoleGuard allowedRoles={["PMB_ADMIN", "PMB_VIEWER"]}>
      <ViewCard
        title={content.pricingSetupHeading}
        content={
          <>
            <Typography variant="body2">
              {formatBillingType(props.subscription)}
            </Typography>

            {subscription.isSeasonal && (
              <Typography variant="body2">
                {content.pricingSetupSeasonal.format({
                  from: monthEnum.format(subscription.seasonFrom),
                  to: monthEnum.format(subscription.seasonTo),
                })}
              </Typography>
            )}

            {subscription.discountType !== "NONE" && (
              <Typography variant="body2">
                {formatSubscriptionDiscount(subscription)}
              </Typography>
            )}

            <Typography variant="body2">
              {content.pricingSetupTransactionHandling.format({
                handling: accountsManagement.format(
                  subscription.accountsManagement,
                ),
              })}
            </Typography>

            {qrTextServiceAddon && (
              <Typography variant="body2">
                {content.qrTextServiceAddon.format({
                  tier: qrTextServiceAddon?.tierOptionLabel ?? "Selected",
                })}
              </Typography>
            )}
          </>
        }
        actions={
          <RoleGuard allowedRoles={["PMB_ADMIN"]}>
            <DialogButton
              variant="outlined"
              renderDialog={(state) => {
                return <LocationPricingSetupDialog {...state} {...props} />;
              }}
            >
              {content.updateButtonLabel}
            </DialogButton>
          </RoleGuard>
        }
      />
    </RoleGuard>
  );
}

function PaymentFrequencyCard(props: ViewProps) {
  const { subscription } = props;
  const permissions = useQueryPermissions([
    { type: "CHANGE_SUBSCRIPTION_PAYMENT_FREQUENCY", subscription },
    { type: "VIEW_ANNUAL_PROMO", subscription },
  ]);

  return (
    <ViewCard
      title={content.paymentFrequencyHeading.format({
        frequency: paymentFrequency
          .format(props.subscription.paymentFrequency)
          .toLowerCase(),
      })}
      content={
        props.subscription.status === "ACTIVE" ? (
          <>
            {content.paymentFrequencyBody.format({
              nextPaymentDate: new Date(
                props.subscription.nextPaymentDate ?? 0,
              ),
            })}

            {permissions.isAllowed("VIEW_ANNUAL_PROMO") && (
              <Box sx={{ marginTop: 3 }}>
                <TextWithIcon
                  icon={<Gift size="1em" />}
                  color="success.main"
                  variant="body1"
                >
                  {content.annualPromo}
                </TextWithIcon>
              </Box>
            )}
          </>
        ) : (
          content.paymentFrequencyBodyInactive
        )
      }
      actions={
        permissions.isAllowed("CHANGE_SUBSCRIPTION_PAYMENT_FREQUENCY") && (
          <DialogButton
            variant="outlined"
            renderDialog={(state) => (
              <PaymentFrequencyDialog {...state} {...props} />
            )}
          >
            Change frequency
          </DialogButton>
        )
      }
    />
  );
}

function BillingAddressCard(props: ViewProps) {
  return (
    <ViewCard
      title={content.billingAddressHeading}
      content={
        <>
          <Typography variant="body1" fontWeight="bold" marginBottom={2}>
            {props.location.company.name}
          </Typography>
          <Address {...props.location.company.address} />
        </>
      }
    />
  );
}

function ViewPaymentDetailButton(props: { paymentId: number }) {
  return (
    <DialogButton
      variant="outlined"
      renderDialog={(dialogState) => {
        return <PaymentDetailDialog {...dialogState} {...props} />;
      }}
    >
      View
    </DialogButton>
  );
}

function PaymentActions(
  props: Schema["PaymentListItem"] & {
    paymentMethod?: Schema["PaymentMethod"];
    revalidateView: () => void;
  },
) {
  const { paymentMethod } = props;
  const isPmbAdmin = useUserHasRole(["PMB_ADMIN", "SYSTEM_ADMIN"]);
  const isCompanyAdmin = useUserHasRole(["COMPANY_ADMIN"]);

  if (props.paymentType === "REINSTATEMENT_FEE") {
    const canBePaid = props.status === "DUE" || props.status === "OVERDUE";

    if (isCompanyAdmin && canBePaid && paymentMethod) {
      return (
        <ButtonGroup>
          <DialogButton
            variant="outlined"
            renderDialog={(dialogProps) => {
              return (
                <PayFeeDialog
                  {...dialogProps}
                  paymentId={props.id}
                  locationId={props.locationId}
                  onSuccess={props.revalidateView}
                  paymentMethod={paymentMethod}
                />
              );
            }}
          >
            Pay fee
          </DialogButton>

          <ViewPaymentDetailButton paymentId={props.id} />
        </ButtonGroup>
      );
    }

    if (isPmbAdmin && canBePaid) {
      return (
        <ButtonGroup>
          <DialogButton
            variant="outlined"
            renderDialog={(dialogProps) => {
              return (
                <ApplyDiscountDialog
                  {...dialogProps}
                  payment={props}
                  revalidateView={props.revalidateView}
                />
              );
            }}
          >
            Apply discount
          </DialogButton>

          <ViewPaymentDetailButton paymentId={props.id} />
        </ButtonGroup>
      );
    }

    return <ViewPaymentDetailButton paymentId={props.id} />;
  }

  switch (props.status) {
    case "PENDING":
    case "DUE":
    case "OVERDUE": {
      // We cannot use RoleGuard here because ButtonGroup doesn't filter out
      // null children, and so the "view payment" detail button appears with
      // flat sides as if there was a button next to it even though there
      // is none.
      if (isPmbAdmin) {
        return (
          <ButtonGroup>
            <DialogButton
              variant="outlined"
              renderDialog={(dialogProps) => {
                return (
                  <SettleManuallyDialog
                    {...dialogProps}
                    paymentId={props.id}
                    onSuccess={props.revalidateView}
                  />
                );
              }}
            >
              Settle manually
            </DialogButton>

            <ViewPaymentDetailButton paymentId={props.id} />
          </ButtonGroup>
        );
      }

      const canBePaidManually =
        props.status === "OVERDUE" ||
        props.status === "DUE" ||
        (props.status === "PENDING" &&
          props.lastTransaction?.status === "FAILED");

      if (isCompanyAdmin && canBePaidManually && paymentMethod) {
        return (
          <ButtonGroup>
            <DialogButton
              variant="outlined"
              renderDialog={(dialogProps) => {
                return (
                  <RetryPaymentDialog
                    {...dialogProps}
                    paymentId={props.id}
                    locationId={props.locationId}
                    onSuccess={props.revalidateView}
                    paymentMethod={paymentMethod}
                  />
                );
              }}
            >
              Retry payment
            </DialogButton>

            <ViewPaymentDetailButton paymentId={props.id} />
          </ButtonGroup>
        );
      }

      return <ViewPaymentDetailButton paymentId={props.id} />;
    }

    case "MANUALLY_SETTLED":
    case "PAID":
    default: {
      return (
        <ButtonGroup>
          <DownloadInvoiceButton paymentId={props.id} />
          <ViewPaymentDetailButton paymentId={props.id} />
        </ButtonGroup>
      );
    }
  }
}

function getInvoiceName(paymentId: number) {
  // Using a file format that matches the text inside the invoice
  return `invoice-${paymentId.toString().padStart(8, "0")}.pdf`;
}

function DownloadInvoiceButton(props: { paymentId: number }) {
  const download = useDownloadClient();

  // We cannot directly use an anchor tag like `<a href="/path/to.pdf" download />`
  // in this case as the endpoint requires the Authorization header and there
  // is no way to attach this header to a request triggered via an anchor tag.
  return (
    <Button
      onClick={async () => {
        await download(`/payments/${props.paymentId}/invoice`, {
          filename: getInvoiceName(props.paymentId),
          mimeType: "application/pdf",
        });
      }}
    >
      Download invoice
    </Button>
  );
}

/**
 * Checks whether the payment method is used because it is an override or because
 * it is the company default payment method.
 *
 * Note that a payment method could be the default but at the same time configured
 * as an override. We must differentiate between the two, as they behave slightly
 * differently.
 */
function locationUsesOverride(
  locationId: number,
  paymentMethod: Schema["PaymentMethod"],
) {
  return paymentMethod.isLocationOverrideFor
    ?.map((l) => l.id)
    .includes(locationId);
}

function PaymentInfoCardContent(props: {
  isLoading: boolean;
  paymentMethod?: Schema["PaymentMethod"] | null;
  error?: Schema["Error"];
  locationId: number;
}) {
  const { isLoading, paymentMethod, error, locationId } = props;

  if (!isLoading) {
    if (paymentMethod) {
      const paymentMethodName = getPaymentMethodName(
        paymentMethod.stripePaymentMethod,
      );

      if (locationUsesOverride(locationId, paymentMethod)) {
        return <Box>This location is charged using {paymentMethodName}.</Box>;
      }

      return (
        <Box>
          This location is charged using the <strong>default</strong> company
          payment method ({paymentMethodName}).
        </Box>
      );
    }

    return (
      <Box>
        No payment method configured. Add a payment method in company settings.
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error.main">
        Fetching location payment method failed. {error.message}
      </Typography>
    );
  }

  return <DummyLine width="80%" />;
}

function PaymentInfoCard(props: { companyId: number; locationId: number }) {
  const permissions = useQueryPermissions([
    { type: "MANAGE_COMPANY_PAYMENT_METHODS" },
  ]);
  const { data, error, isLoading } = useClientSWR(
    "/locations/{locationId}/subscription/payment-method",
    { params: { path: { locationId: props.locationId } } },
  );

  return (
    <ViewCard
      title="Payment method"
      content={
        <PaymentInfoCardContent
          locationId={props.locationId}
          isLoading={isLoading}
          paymentMethod={data ? data.selectedPaymentMethod : undefined}
          error={error}
        />
      }
      actions={
        permissions.isAllowed("MANAGE_COMPANY_PAYMENT_METHODS") && (
          <NavLinkButton
            to={`/company/${props.companyId}/payment-methods`}
            variant="outlined"
          >
            Manage in company settings
          </NavLinkButton>
        )
      }
    />
  );
}

function PaymentHistoryCardFetch(props: {
  locationId: number;
  companyId: number;
}) {
  const pageable = usePageable({
    initialSortProp: "dueDate",
    initialSortDirection: "desc",
  });
  const {
    data: payments,
    error,
    mutate,
  } = useClientSWR("/locations/{locationId}/payments", {
    params: {
      path: { locationId: props.locationId },
      query: pageable.query,
    },
  });

  const { data } = useClientSWR(
    "/locations/{locationId}/subscription/payment-method",
    { params: { path: { locationId: props.locationId } } },
  );

  if (error) {
    return <LocationFetchErrorCard error={error} />;
  }

  return (
    <Card>
      <CardHeader
        title="Payment history"
        titleTypographyProps={{ variant: "h5", component: "h2" }}
      />
      {payments?.content.length === 0 ? (
        <CardContent>No payments yet.</CardContent>
      ) : (
        <ViewTable
          head={
            <TableRow>
              <TableColumnHeadCell>Date</TableColumnHeadCell>
              <TableColumnHeadCell>Price</TableColumnHeadCell>
              <TableColumnHeadCell>Status</TableColumnHeadCell>
              <TableColumnHeadCell>Type</TableColumnHeadCell>
              <TableCell />
            </TableRow>
          }
          body={
            !payments ? (
              <DummyRows columns={5} rows={pageable.pageSize} />
            ) : (
              payments.content.map((payment) => {
                return (
                  <TableRow key={payment.id}>
                    <TableCell>
                      {formatDate(payment.dueDate, "medium")}
                    </TableCell>
                    <TableCell>
                      {formatCurrency("USD", payment.amountDue)}
                    </TableCell>
                    <TableCell>
                      <PaymentStatusChip {...payment} />
                    </TableCell>
                    <TableCell>
                      {payment.paymentType === "REGULAR"
                        ? paymentFrequency.format(payment.paymentFrequency)
                        : paymentType.format(payment.paymentType)}
                    </TableCell>

                    <TableCell align="right">
                      <PaymentActions
                        {...payment}
                        paymentMethod={data?.selectedPaymentMethod}
                        revalidateView={() => mutate()}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            )
          }
          pagination={
            <ViewTablePagination
              {...pageable.paginationProps}
              totalElements={payments?.totalElements}
            />
          }
        />
      )}
    </Card>
  );
}

function LocationSubscriptionView(props: ViewProps) {
  return (
    <>
      <ViewHeader
        title={`${props.location.name} Subscription`}
        breadcrumb={[
          {
            label: "Locations",
            url: `/location/list`,
          },
          {
            label: props.location.name,
            url: `/location/${props.location.id}/profile`,
          },
          {
            label: "Subscription",
          },
        ]}
      />

      <Box sx={{ display: "flex", flexDirection: "column", gap: 6 }}>
        <PricingSetupCard {...props} />

        {props.subscription.status !== "MANUAL" && (
          <SubscriptionDetailsCard {...props} />
        )}

        {props.subscription.status === "ACTIVE" && (
          <>
            <PaymentFrequencyCard {...props} />

            <PaymentInfoCard
              companyId={props.location.company.id}
              locationId={props.location.id}
            />
          </>
        )}

        <BillingAddressCard {...props} />

        <PaymentHistoryCardFetch
          locationId={props.location.id}
          companyId={props.location.company.id}
        />
      </Box>
    </>
  );
}

export function LocationSubscriptionFetch() {
  const locationId = Number(useParams<"locationId">().locationId);
  const location = useClientSWR("/locations/{locationId}", {
    params: { path: { locationId } },
  });
  const subscription = useClientSWR("/locations/{locationId}/subscription", {
    params: { path: { locationId } },
  });
  const paymentStructure = useClientSWR(
    "/locations/{locationId}/payment-structure",
    { params: { path: { locationId } } },
  );
  const error = location.error || subscription.error || paymentStructure.error;
  if (error) {
    return <LocationFetchErrorCard error={error} />;
  }

  if (location.data && subscription.data && paymentStructure.data) {
    return (
      <LocationSubscriptionView
        location={location.data}
        subscription={subscription.data}
        paymentStructure={paymentStructure.data}
        revalidateView={() => {
          location.mutate();
          subscription.mutate();
          paymentStructure.mutate();
        }}
      />
    );
  }

  return <LoadingSpinner />;
}
