import { Alert, AlertProps, Box, Paper } from "@mui/material";
import { ReactNode } from "react";

export type PaperAlertColor = AlertProps["color"];

export function PaperAlert(props: {
  action: ReactNode;
  children: ReactNode;
  icon: ReactNode;
  marginBottom?: number;
  color?: PaperAlertColor;
}) {
  return (
    <Paper sx={{ marginBottom: props.marginBottom }}>
      <Alert
        color={props.color}
        sx={{ borderRadius: "inherit" }}
        icon={props.icon}
        action={
          // Margin for optical alignment
          <Box sx={{ marginTop: "-0.125rem" }}>{props.action}</Box>
        }
      >
        {props.children}
      </Alert>
    </Paper>
  );
}
