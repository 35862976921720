import { useMsal } from "@azure/msal-react";
import { Email } from "@mui/icons-material";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { unpackResponse, useClient } from "../../client";
import { position } from "../../constants/contactPositions";
import { clearLocalStorage } from "../../utils/local-storage";
import { ConfirmDialog } from "../confirm-dialog";
import { EditContactDialog } from "../contact-form-dialog";
import { DialogButton } from "../dialog-button";
import { PaperAlert } from "../paper-alert";
import { useUserInfo } from "../user-info-context";
import { ViewHeader } from "../view-header";

function DeactivateAccountDialog(props: {
  isOpen: boolean;
  contact: { fullName: string; id: number };
  onClose: () => void;
}) {
  const client = useClient();
  const { contact, ...dialogProps } = props;

  const { instance } = useMsal();
  const handleSignOut = async () => {
    // Clean the local storage data when user logout from the application
    clearLocalStorage();

    instance.logoutRedirect({
      account: instance.getActiveAccount(),
    });
  };

  return (
    <ConfirmDialog
      {...dialogProps}
      title={`Deactivate account?`}
      description={`You will no longer have access to PourMyBev. You cannot undo this action.`}
      submitLabel="Deactivate"
      submitColor="error"
      onSubmit={async () => {
        await unpackResponse(
          client.DELETE("/contacts/{contactId}", {
            params: { path: { contactId: contact.id } },
          }),
        );

        handleSignOut();
      }}
    />
  );
}

function CancelEmailChangeDialog(props: {
  contact: { id: number };
  newEmail: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const client = useClient();
  const { contact, ...dialogProps } = props;

  return (
    <ConfirmDialog
      {...dialogProps}
      title="Cancel email change?"
      description={`This action will cancel your request to change your account email to ${props.newEmail}.`}
      // Normally we match the dialog heading with the action button, but it is confusing to have a button that
      // reads 'cancel' next to one that says 'dismiss'.
      submitLabel="Confirm"
      onSubmit={async () => {
        await unpackResponse(
          client.DELETE("/email/{contactId}", {
            params: { path: { contactId: contact.id } },
          }),
        );

        props.onSuccess();
      }}
    />
  );
}

export function UserProfileView() {
  const { principal: user, revalidate } = useUserInfo();
  const newEmail = user.newEmail;

  return (
    <>
      <ViewHeader title="Profile" breadcrumb={[{ label: "Profile" }]} />

      {newEmail && (
        <PaperAlert
          color="success"
          action={
            <DialogButton
              size="small"
              color="success"
              renderDialog={(dialogProps) => {
                return (
                  <CancelEmailChangeDialog
                    {...dialogProps}
                    contact={user}
                    newEmail={newEmail}
                    onSuccess={revalidate}
                  />
                );
              }}
            >
              Cancel change
            </DialogButton>
          }
          icon={<Email fontSize="small" />}
          marginBottom={6}
        >
          Follow instructions in your inbox to confirm your new email address (
          {user.newEmail}).
        </PaperAlert>
      )}

      <Paper
        sx={{
          padding: 9,
          display: "flex",
          flexDirection: "column",
          gap: 9,
        }}
      >
        <Typography component={"h2"} variant="h4">
          User Account
        </Typography>

        <Box
          sx={{ display: "grid", gridTemplateColumns: "6rem 1fr", gap: "1rem" }}
        >
          <div>Name</div>
          <Typography component="div" variant="body1">
            {user.fullName}
          </Typography>

          <div>Email</div>
          <Typography component="div" variant="body1">
            {user.email}
          </Typography>

          <div>Mobile</div>
          <Typography component="div" variant="body1">
            {user.mobile}
          </Typography>

          <div>Position</div>
          <Typography component="div" variant="body1">
            {position.format(user.position)}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", gap: 4 }}>
          <DialogButton
            variant="contained"
            color="primary"
            renderDialog={(dialogProps) => {
              return (
                <EditContactDialog
                  {...dialogProps}
                  onSuccess={revalidate}
                  contact={user}
                  positionDisabled
                  positionOptions={[]}
                  emailEnabled
                  note={
                    <Typography variant="body2" sx={{ marginTop: 4 }}>
                      If you set a new email address, you will have to confirm
                      the change by following instructions sent to the new
                      address.
                    </Typography>
                  }
                />
              );
            }}
          >
            Edit profile
          </DialogButton>
        </Box>

        <Divider />

        <Box>
          <Typography sx={{ maxWidth: "40rem", marginBottom: 4 }}>
            If you want to permanently close your account, you can do so using
            the button below. Beware that you will lose all access to PourMyBev.
            To regain access, you will have to be re-invited.
          </Typography>

          <DialogButton
            variant="contained"
            color="info"
            renderDialog={(dialogProps) => {
              return (
                <DeactivateAccountDialog {...dialogProps} contact={user} />
              );
            }}
          >
            Deactivate account
          </DialogButton>
        </Box>
      </Paper>
    </>
  );
}
