import { Box, Card, TableCell, TableRow } from "@mui/material";
import { useClientSWR } from "../../client";
import { Schema } from "../../client/types";
import { warrantyStatus } from "../../constants/warrantyStatus";
import { formatCurrency, formatDate } from "../../utils/formatters";
import { DummyRows } from "../dummy-rows";
import { WarrantyReturnFetchErrorCard } from "../info-card";
import { TableCellLink, TableColumnHeadCell } from "../table-elements";
import { Pageable, usePageable } from "../use-pageable";
import { ViewHeader } from "../view-header";
import { ViewTable } from "../view-table";
import { ViewTablePagination } from "../view-table-pagination";
import { WarrantyReturnMenu } from "../warranty-return-menu";

export function WarrantyReturnsView() {
  return (
    <>
      <ViewHeader
        title="All Warranty Returns"
        breadcrumb={[
          {
            label: "All warranty returns",
          },
        ]}
      />
      <WarrantyReturnTableFetch />
    </>
  );
}

export function WarrantyReturnTableFetch() {
  const pageable = usePageable({
    initialSortProp: "dueDate",
    initialSortDirection: "desc",
  });

  const {
    data: warrantyReturns,
    error: warrantyReturnsError,
    mutate,
  } = useClientSWR("/warranty-returns", { params: { query: pageable.query } });

  if (warrantyReturnsError) {
    return <WarrantyReturnFetchErrorCard error={warrantyReturnsError} />;
  }

  return (
    <>
      <WarrantyReturnsTabel
        warrantyReturns={warrantyReturns}
        pageable={pageable}
        revalidateView={() => mutate()}
      />
    </>
  );
}

/**
 * WarrantyReturnsTabel is a stateless component which renders a list of
 * transactions if passed, otherwise a pending state of dummy data.
 */
function WarrantyReturnsTabel(props: {
  warrantyReturns?: {
    totalElements?: number;
    content: Schema["WarrantyReturn"][];
  };
  pageable: Pageable;
  revalidateView: () => void;
}) {
  const ROW_HEIGHT = 75;
  const { warrantyReturns } = props;
  const { pageSize } = props.pageable;

  return (
    <Card>
      <ViewTable
        tableLayout="fixed"
        head={
          <TableRow>
            <TableColumnHeadCell width="25%">Description</TableColumnHeadCell>
            <TableColumnHeadCell>Due date</TableColumnHeadCell>
            <TableColumnHeadCell>Fee</TableColumnHeadCell>
            <TableColumnHeadCell>Status</TableColumnHeadCell>
            <TableColumnHeadCell>Location</TableColumnHeadCell>
            <TableColumnHeadCell>Company</TableColumnHeadCell>
            <TableCell width={ROW_HEIGHT} align="right" />
          </TableRow>
        }
        body={
          !warrantyReturns ? (
            <DummyRows columns={7} rows={pageSize} rowHeight={ROW_HEIGHT} />
          ) : (
            warrantyReturns.content.map((warrantyReturn) => {
              return (
                <TableRow
                  key={warrantyReturn.id}
                  sx={{ height: ROW_HEIGHT }}
                  hover
                >
                  <TableCell>{warrantyReturn.itemDescription}</TableCell>

                  <TableCell>
                    {formatDate(warrantyReturn.dueDate, "long")}
                  </TableCell>

                  <TableCell>
                    {formatCurrency("USD", warrantyReturn.feeAmount)}
                  </TableCell>

                  <TableCell>
                    <Box
                      component="span"
                      sx={{
                        color:
                          warrantyReturn.status === "OVERDUE"
                            ? "error.main"
                            : warrantyReturn.status === "PENDING"
                              ? "warning.main"
                              : "textPrimary",
                      }}
                    >
                      {warrantyStatus.format(warrantyReturn.status)}
                    </Box>
                  </TableCell>

                  <TableCellLink
                    to={`/location/${warrantyReturn.locationId}/profile`}
                  >
                    {warrantyReturn.locationName}
                  </TableCellLink>

                  <TableCell>{warrantyReturn.companyName}</TableCell>

                  <TableCell align="right">
                    <WarrantyReturnMenu
                      warrantyReturn={warrantyReturn}
                      revalidateView={props.revalidateView}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          )
        }
        pagination={
          props.warrantyReturns && (
            <ViewTablePagination
              {...props.pageable.paginationProps}
              totalElements={props.warrantyReturns.totalElements}
            />
          )
        }
      />
    </Card>
  );
}
