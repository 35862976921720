import { Warning } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useClientSWR } from "../client";
import { Schema } from "../client/types";
import { useCompanySubscriptions } from "../hooks/useCompanySubscriptions";
import { formatDate } from "../utils/formatters";
import { NavLinkButton } from "./nav-link-button";
import { PaperAlert, PaperAlertColor } from "./paper-alert";
import { RoleGuard } from "./role-guard";

type WarningType = Schema["WarningType"] | "PENDING_ACTIVATION";

type WarningProps = {
  description: string;
  button?: { href: string; label: string };
  color?: PaperAlertColor;
  allowedRoles?: Schema["Role"][];
};

function toWarningProps(
  context: "LOCATION" | "COMPANY",
  subject: { id: number; name: string; dueDate?: string },
  warningType: WarningType,
): WarningProps {
  switch (warningType) {
    case "PENDING_ACTIVATION": {
      return {
        description:
          context === "COMPANY"
            ? `${subject.name} subscription is not set up.`
            : "Location subscription is not set up.",
        button: {
          href: `/location/${subject.id}/subscription`,
          label: "Set up",
        },
        allowedRoles: ["COMPANY_ADMIN"],
      };
    }

    case "PAYMENT_OVERDUE": {
      return {
        description:
          context === "COMPANY"
            ? `${subject.name} has one or more overdue payments.`
            : `One or more payments are overdue.`,
        button: {
          href: `/location/${subject.id}/subscription`,
          label: "View",
        },
      };
    }

    case "MISSING_PAYMENT_METHOD":
      return {
        description:
          context === "COMPANY"
            ? `${subject.name} has no payment method set up.`
            : "Missing payment method.",
        button: {
          href: `/location/${subject.id}/subscription`,
          label: "Set up",
        },
        allowedRoles: ["COMPANY_ADMIN"],
      };

    case "PENDING_WARRANTY_RETURN": {
      const when = subject.dueDate
        ? formatDate(subject.dueDate, "medium")
        : "soon";

      return {
        description:
          context === "COMPANY"
            ? `${subject.name} has a pending warranty return due ${when}.`
            : `Pending warranty return due ${when}.`,
        button: {
          href: `/location/${subject.id}/warranty-returns`,
          label: "View",
        },
      };
    }

    case "LOCATION_NOT_SUPPORTED": {
      return {
        description:
          context === "COMPANY"
            ? `${subject.name} is currently not supported.`
            : "This location is currently not supported.",
        button:
          context === "COMPANY"
            ? {
                href: `/location/${subject.id}/profile`,
                label: "View",
              }
            : undefined,
        color: "error",
      };
    }

    case "MISSING_PRIMARY_CONTACT": {
      return {
        description: "Missing primary contact.",
        allowedRoles: [
          "SYSTEM_ADMIN",
          "PMB_ADMIN",
          "COMPANY_ADMIN",
          "LOCATION_ADMIN",
        ],
      };
    }
  }
}

/**
 * Stateless component which displays Toast message containing
 * the error message regarding the subscription status.
 */
export function LocationWarningsAlert(props: {
  location: Schema["LocationBase"];
}) {
  const { location } = props;
  const { data: subscription, error: errorSub } = useClientSWR(
    "/locations/{locationId}/subscription",
    { params: { path: { locationId: location.id } } },
  );
  const { data: warnings, error: errorWarnings } = useClientSWR(
    "/locations/{locationId}/warnings",
    { params: { path: { locationId: location.id } } },
  );

  // Since this element usually doesn't appear, we don't want to show a loading
  // indicator, in order not to introduce too much layout shift. We also don't
  // want to show errors -- if there was an issue, it will probably show up
  // in other places too and we don't want to make this page a mess.
  if (errorSub || errorWarnings || !warnings || !subscription) {
    return null;
  }

  return (
    <Stack gap={3} sx={{ marginBottom: 6 }}>
      {subscription.status === "PENDING_ACTIVATION" && (
        <WarningAlert
          {...toWarningProps("LOCATION", location, "PENDING_ACTIVATION")}
        />
      )}

      {warnings.map(({ type }, index) => {
        return (
          <WarningAlert
            key={index}
            {...toWarningProps("LOCATION", location, type)}
          />
        );
      })}
    </Stack>
  );
}

export function CompanyBillingToast(props: { companyId: number }) {
  const { companyId } = props;
  const { data: subscriptions, error: errorSub } =
    useCompanySubscriptions(companyId);
  const { data: warnings, error: errorWarnings } = useClientSWR(
    "/companies/{companyId}/warnings",
    { params: { path: { companyId } } },
  );

  // Since this element usually doesn't appear, we don't want to show a loading
  // indicator, in order not to introduce too much layout shift. We also don't
  // want to show errors -- if there was an issue, it will probably show up
  // in other places too and we don't want to make this page a mess.
  if (errorSub || errorWarnings || !warnings || !subscriptions) {
    return null;
  }

  return (
    <Stack gap={3} sx={{ marginBottom: 6 }}>
      {subscriptions.map(({ status, location }) => {
        if (status === "PENDING_ACTIVATION") {
          return (
            <WarningAlert
              {...toWarningProps("COMPANY", location, "PENDING_ACTIVATION")}
            />
          );
        }

        return null;
      })}

      {warnings.map(({ type, detail }, index) => {
        return (
          <WarningAlert
            key={index}
            {...toWarningProps(
              "COMPANY",
              detail
                ? {
                    id: detail.locationId,
                    name: detail.locationName,
                    dueDate: "dueDate" in detail ? detail.dueDate : undefined,
                  }
                : { id: 0, name: "Unknown" },
              type,
            )}
          />
        );
      })}
    </Stack>
  );
}

function WarningAlert({
  description,
  button,
  color,
  allowedRoles,
}: WarningProps) {
  const alertColor = color ?? "warning";

  const defaultRoles = allowedRoles ?? [
    "SYSTEM_ADMIN",
    "PMB_ADMIN",
    "COMPANY_ADMIN",
    "LOCATION_ADMIN",
    "LOCATION_VIEWER",
  ];

  return (
    <RoleGuard noDefaultSystemAdmin allowedRoles={defaultRoles}>
      <PaperAlert
        color={alertColor}
        icon={<Warning fontSize="small" />}
        action={
          button ? (
            <NavLinkButton color={alertColor} size="small" to={button.href}>
              {button.label}
            </NavLinkButton>
          ) : null
        }
      >
        {description}
      </PaperAlert>
    </RoleGuard>
  );
}
