import { Schema } from "../client/types";
import { EnumFormatter } from "../utils/enum-formatter";

export const warrantyStatus = new EnumFormatter<Schema["WarrantyReturnStatus"]>(
  {
    PAID: "Paid",
    RECEIVED: "Received",
    PENDING: "Pending return",
    OVERDUE: "Payment overdue",
  },
);
