import { ReactNode } from "react";
import { Schema } from "../client/types";
import { includesAnyOf } from "../utils/one-of";
import { useUserInfo } from "./user-info-context";

/**
 * Checks whether the current user has one of the roles supplied to it.
 *
 * This hook is mostly useful when you need to make sure that an argument
 * to a function/component is falsy instead of just passing `<RoleGuard/>`,
 * which — even if it renders `null` — is still a React Element (i.e. not
 * falsy).
 */
export function useUserHasRole(allowedRoles: Schema["Role"][]) {
  const { roles } = useUserInfo();
  const hasAllowedRole = includesAnyOf<Schema["Role"]>(roles, allowedRoles);
  return hasAllowedRole;
}

/**
 * Hides nested elements if the current user doesn't have one of the allowed
 * roles.
 */
export function RoleGuard(props: {
  /**
   * List of roles that are allowed to see/interact with the children
   * of this component.
   *
   * Note that SYSTEM_ADMIN is automatically added to this list as
   * they are allowed to do basically everything in the app.
   */
  allowedRoles: Schema["Role"][];
  noDefaultSystemAdmin?: boolean;
  children: ReactNode;
}) {
  // Unless disabled, System Admin is allowed to see everything
  const roles: Schema["Role"][] = props.noDefaultSystemAdmin
    ? props.allowedRoles
    : [...props.allowedRoles, "SYSTEM_ADMIN"];

  const isAllowed = useUserHasRole(roles);

  if (!isAllowed) {
    return null;
  }

  return <>{props.children}</>;
}
